import { graphql } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import CoverImage from "../components/coverImage";

function ChangelogTemplate(props) {
  const {
    data: {
      wordpressPage: {
        acf
      },
      allMarkdownRemark: {
        edges: [first],
      },
    },
  } = props;

  return (
    <>
      {(acf && acf.background_image) &&
        <CoverImage fluid={acf.background_image.localFile.childImageSharp.fluid} />
      }
      <div className="changelog-container">
        <div className="changelog-list">
          <div>{ReactHtmlParser(first.node.html)}</div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: { eq: "changelog" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      yoast_head
      acf {
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          headings {
            depth
            value
          }
          frontmatter {
            # Assumes you're using title in your frontmatter.
            title
          }
        }
      }
    }
  }
`;

export default ChangelogTemplate;
